.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.profileCntnrDiv {
  position: fixed;
  right: 0px;
  top: 0px;
  /* width: 18%; */
  width: 16.5rem;
  height: 100vh;
  z-index: 11;
  padding: 2rem 1.5rem 1.5rem;
  background: #02174d;
  color: #919eab;
}
.profileCntnrDiv .languageDropdown-options #myDropdownB9:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(23, 47, 112);
}
.profileCntnrDiv h6,
.profileCntnrDiv p {
  word-break: break-all;
  color: rgb(249, 250, 251);
  font-size: 14px;
}
.profileCntnrDiv .userName {
  display: block;
  margin-top: 13px;
  margin-left: 12px;
}
.profileCntnrDiv .profile-heading {
  font-size: 20px;
  margin-top: -1px;
}
.profileCntnrDiv p {
  width: 7rem;
}
.profileLangaugeHldr {
  max-height: 35vh;
  overflow: auto;
}
.language-dropDown {
  background: rgb(2, 22, 78) !important;
  border-radius: 4px !important;
  border: 0.5px solid rgb(255, 255, 255) !important;
  min-width: 13.5rem;
  height: 37px;
  /* min-width: 11.5rem !important; */
  min-width: 13.5rem !important;
}
.languageDropdown-options {
  background: rgb(2, 23, 78);
  border-radius: 8px;
  border: 1px solid rgb(1, 142, 255);
  margin-left: 1rem;

  position: absolute;
  z-index: 1;
  overflow-x: auto;
  max-height: 29vh;
  /* width: 11.5rem !important; */
  width: 13.5rem !important;
}
#myInput.languageSearch {
  border: 1px solid rgb(1, 142, 255) !important;
  background-position: 14px 7px !important;
  width: 87% !important;
  color: rgb(239, 242, 245);
  background-color: transparent !important;
  margin: 9px 13px 15px 13px;
}

.languageDropdown-options .dropdown-content#myDropdownB9 {
  width: auto !important;
  font-size: 0.8rem !important;
  height: 38px !important;
}
.profileCntnrDiv .hmcc-drop-btn {
  padding: 3px 15px;
}

input[type="checkbox"].switchTheme.DarkTheme:checked + div > div {
  background-color: #1dc7b3;
}
input[type="checkbox"].switchTheme.DarkTheme:checked + div {
  background-color: rgba(255, 255, 255, 0.15) !important
}
.userName:hover,
.userName {
  color: #fff !important;
}
.profileCntnrDiv p.small {
  font-size: 0.65rem;
}
.profileCntnrDiv .profileIcon {
  width: 1rem;
  vertical-align: text-top;
}
.profileCloseBtnAncr {
  cursor: pointer;
}
.profileCloseBtn {
  width: 1rem;
  cursor: pointer;
  right: 0.9rem;
  top: 1.2rem;
  position: absolute;
}
.profile-img {
  border-radius: 50%;
  /* height: 3.5rem;
  width: 3.5rem; */
  height: 3rem;
  width: 3rem;
}
.switchContainerTheme {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(-10%, -50%, 0);
}
.switchContainerTheme.disable {
  pointer-events: none;
  opacity: 0.7;
}
input[type="checkbox"].switchTheme {
  position: absolute;
  opacity: 0;
}
input[type="checkbox"].switchTheme {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"].switchTheme + div {
  vertical-align: middle;
  width: 2.7rem;
  height: 1.5rem;
  border-radius: 999px;
  background-color: #fff;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type="checkbox"].switchTheme + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type="checkbox"].switchTheme + div span:nth-child(1) {
  margin-left: 15px;
}

input[type="checkbox"].switchTheme + div span:nth-child(2) {
  margin-left: 55px;
}

input[type="checkbox"].switchTheme:checked + div {
  width: 2.7rem;
  background-position: 0 0;
  background-color: #fff;
}

input[type="checkbox"].switchTheme + div {
  width: 2.7rem;
  height: 1.5rem;
  transform: scale(0.8);
  transform-origin: center;
}

input[type="checkbox"].switchTheme:checked + div {
  background-color: #fff;
}

input[type="checkbox"].switchTheme + div > div {
  float: left;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: inherit;
  background: rgb(1, 142, 255);
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 0px;
  margin-left: 1px;
  text-align: center;
}

input[type="checkbox"].switchTheme:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: rgb(1, 142, 255);
}

input[type="checkbox"].bigswitch.switchTheme + div > div {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 1px;
}

input[type="checkbox"].switchTheme:checked + div > div {
  -webkit-transform: translate3d(1.2rem, 0, 0);
  transform: translate3d(1.2rem, 0, 0);
}
input[type="checkbox"].switchTheme + div > div img {
  height: 0.6rem;
}
.hmcc-drop-btn {
  padding: 2px 15px;
  width: auto;
  border-radius: 30px;
  text-align: left;
  margin: 0px 5px 0.25rem;
  outline: none;
  line-height: 25px;
  font-size: 0.75rem;
  font-family: "GraphikMedium", Arial, sans-serif;
}

.hmcc-drop-btn img {
  position: absolute;
  right: 31px;
  top: 22px;
}
.logoutIcon {
  border-radius: 2rem;
  padding: 2px 11px 2px 12px;
  border: 1px solid rgb(151, 151, 151);
  cursor: pointer;
  font-size: 15px;
  color: rgb(255, 255, 255);
  position: absolute;
  right: 21px;
  bottom: 16px;
}
.homeLanguageSearch {
  border: 1px solid rgb(1, 142, 255) !important;
  margin: 15px 13px 15px 13px;
  padding: 6px 6px 6px 40px;
  background: url(Images/search-grey-icon.png) 10px 5px scroll no-repeat;
  background-size: 16px;
}
.homeLanguageSearch#myInput {
  border: 1px solid rgb(1, 142, 255) !important;
  color: #fff !important;
}
#myInput.languageSearch {
  border: 1px solid rgb(1, 142, 255) !important;
  background-position: 14px 7px !important;
  width: 87% !important;
  color: rgb(239, 242, 245);
  background-color: transparent !important;
  margin: 9px 13px 15px 13px;
}
#myInput {
  max-height: 5vh !important;
  width: 94% !important;
  border-radius: 30px !important;
  outline: none !important;
}
.dropdown-content {
  position: initial !important;
  max-height: 33vh !important;
  width: 19vw !important;
  overflow: auto;
}
#myDropdownB9 {
  height: 45px;
  display: flex;
  width: 27.2rem!important;
  align-items: center;
  padding-left: 1rem;
}
.text-left {
  text-align: left!important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Home Page */

.header-left {
  padding: 2rem 0 0 2.8rem !important;
}

.header-right {
  text-align: right;
  padding-top: 26px;
  padding-right: 33px !important;
}

.profileIcon {
  height: 19.17px;
  width: 19.17px;
  margin-left: 1rem;
  cursor: pointer;
}

/* Home Page End */

/* footer */

.footer-merge {
  width: 100%;
}

.footer-up {
  background: transparent;
  border-top: 2px solid #6373816e;
  margin-top: 1.3rem;
  position: relative;
  /* bottom: 0; */
  min-width: 100%;
}
.footer-up .acclogo li {
  padding: 1.2rem 0 0.2rem 0;
}
.footer-up .otherlinks li {
  padding: 1.2rem 0 0.2rem 0;
}
.footer-up .copyright li {
  padding: 1.6rem 0 0.2rem 0;
}
.footer-up li {
  display: inline-flex;
  margin-right: 1.4rem;
}

.footer-up .col-7 li {
  display: inline-flex;
  margin-right: 1.5rem;
  padding-top: 1.65rem;
  font-family: graphikmedium;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
}
.footer-up .col-4 li {
  font-family: GraphikRegular;
  color: #ffffffc7;
  font-size: 0.85rem;
}


.footer-up .col-7 a {
  color: #ffffffc7;
}

.footer-up .col-7 a:hover {
  color: rgb(1, 142, 255);
}

.body-container {
  min-height: 67.4vh;
  padding: 1rem 2.8rem 0 2.8rem;
}

.power {
  color: rgb(1, 142, 255);
  font-family: GraphikSuper;
}

.intOps {
  color: #fff;
  font-family: GraphikSuper;
  line-height: 2.4rem;
}

.icon {
  height: 32px;
  max-width: 40px;
}

.tile-container {
  padding: 1.5rem 0 0 1.4rem;
}

.tile::after {
  position: absolute;
  bottom: -8px;
  left: 15px;
  width: 80%;
  content: '';
  border-bottom: 1.5px solid rgb(255 255 255 / 35%);
}

.appName {
  color: #fff;
  font-size: 32px;
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.appdesc {
  font-size: 11.5px;
  color: #fff;
  margin-bottom: 1rem;
}

.userName {
  font-size: 14px;
  color: #ffffffd1;
}

.dealName {
  color: #fff;
  font-family: Graphiksemibold;
  font-size: 16px;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}

.description {
  color: #fff;
  font-family: GraphikRegular;
  font-size: 12px;
  /* text-align: justify; */
  /* margin-bottom: 1rem; */
  max-width: 18rem;
  min-height: 3rem;
}

.getStarted {
  background: rgb(1, 142, 255);
  border-radius: 16px;
  height: 32px;
  width: 120px;
  border: none;
  font-family: graphikmedium;
  font-size: 12px;
  color: #ffffffe3;
}
.getStarted a {
  text-decoration: none !important;
}
.getStarted a:hover {
  text-decoration: none !important;
  color: #ffffffe3 !important;
}

.helpIcon {
  position: fixed;
  cursor: pointer;
  bottom: 2.5rem;
  right: 2rem;
  height: 60px;
  width: 60px;
}

.acc-logo {
  height: 25px;
  width: 95px;
}

.footer-bottom {
  box-shadow: 0px -2px 16px 0px rgba(33, 43, 54, 0.1), 0px -1px 0px 0px rgb(223, 227, 232);
  background: rgb(255, 255, 255);
  padding-left: 48px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-bottom li {
  margin-right: 2.7rem;
  color: rgb(145, 158, 171);
  font-family: GraphikRegular;
  font-size: 12px;
  display: inline-flex;
  padding: 1.2rem 0 0.3rem 0;
}

.footerLinks {
  color: rgb(145, 158, 171);
  text-decoration: none !important;
}

.DarkTheme {
  background: url(../src/Images/DarkBack.png) center center scroll no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.footer-space {
  height: 45px;
}

.feedback-modal-header {
  height: 56px;
  background: linear-gradient(-225deg, rgb(6, 66, 147) 0%, rgb(4, 46, 115) 50%, rgb(14, 43, 102) 100%);
  border-radius: 16px 16px 0px 0px;
}

.feedback-modal .modal-content {
  border-radius: 1.1rem !important;
  border: none !important;
}
.feedback-modal-title {
  /* width: 299px; */
  /* height: 16px; */
  color: #ffffffdb;
  font-size: 14px !important;
  font-family: 'GraphikRegular' !important;
  /* font-weight: normal;
  letter-spacing: -0.33px; */
}

.feed-button {
  width: 217px;
  height: 84px;
  background: rgb(255, 255, 255);
  /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15);
  border-radius: 8px;
}

.feedback-footer {
  height: 72px;
  background: rgb(244, 246, 248);
  border-radius: 0px 0px 16px 16px;
  border-top: none !important;
  padding-right: 1.24rem !important;
}

.footer-btn {
  width: 95px;
  height: 40px;
  border-radius: 20px !important;
}

.feedback-label {
  width: 361px;
  height: 22px;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: GraphikMedium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 22px;
  margin-left: 16px;
  margin-bottom: 16px;
}

.feedback-productInfo {
  width: 333px;
  height: 84px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15); */
  border-radius: 8px;
  margin-bottom: 64px;
  margin-left: 32px;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
}
.feedbackNewIdea{
  height: 1.3rem;
  margin-top: 0.9rem;
}
.feedbackIdea{
  height: 1.3rem;
  margin-top: 1rem;
}
.selectedFeedbackType {
 /* width: 217px; */
  height: 4px;
  background: rgb(0, 46, 153);
  border-radius: 0px 0px 4px 4px;
  position: relative;
  bottom: -18%;
}
.attachmentFont{
  font-style: italic;
  font-size: 0.8rem;
  text-align: right;
}
.filetypeFont{
  font-size: 0.8rem;
  text-align: right;

}
.feedbackLabel {
  /* height: 22px; */
  color: rgb(99, 115, 129);
  font-size: 1rem;
  font-family: GraphikMedium;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: 4%;
}
.feedbackLabelSelect{
  color: rgb(0,46,153);
  font-size: 1rem;
  font-family: GraphikMedium;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: 4%;
}
.feedback-modal-header-submit {
  height: 56px;
  background: linear-gradient(
    -225deg,
    rgb(6, 66, 147) 0%,
    rgb(4, 46, 115) 50%,
    rgb(14, 43, 102) 100%
  );
}
.feedbackSubmitted-modal .feedback-modal .modal-content{
  margin-top: 9rem;
}
.feedbackSubmitted-modal{
  background-color: rgba(0,0,0,0.5);
}
.feedbackSubmitDiv{
	background-color: #fff;
	width: 30% !important;
	margin: 15% auto auto;
	border-radius: 16px;
	padding:"20px";
	overflow-x:hidden;
	overflow-y: auto;
}
.submitFeedbackFocus .focus, .submitFeedbackFocus:focus {
  box-shadow: none;
 }
 .feedback-footer .btn-primary.focus, .feedback-footer .btn-primary:focus {
   box-shadow: none;
 }


.feedback-select {
  width: 280px;
  margin-left: 16px;
}
.feedbackLabelChange {
  color: rgb(0, 46, 153);
  font-size: 1rem;
  font-family: GraphikMedium;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: 4%;
}
.feedback-submit{
  margin-top: 1.3rem;
  position: absolute;
}
.feedbackSubmittext{
  color: grey;
  font-weight: bold;
  margin-left: 2.7rem;
}
.newFeedback{
  color: grey;
  font-size: small;
  margin-top: 1rem;
}
.newFeedbackSubmitted{
  text-decoration: underline;
  color: rgb(0, 123, 255);
  cursor: pointer;
}
.feedbackradioButtons {
  width: auto;
  color: gray;
  font-size: 1rem;
  margin-bottom: 2rem;
  margin-left: 1rem !important;
}
.fileUploadMessage {
  color: red;
  background-color: antiquewhite;
  /* margin-left: 39.4rem; */
  font-size: 0.8rem;
  /* float: right; */
}
.attachmentPosition {
  color: rgb(0, 46, 153);
  cursor: pointer;
  margin-bottom: 0rem !important;
  display: inherit;
  text-align: right;
  margin-right: 70px;
}


#feedback_Body {
  padding: 1.35rem !important;
}
.uploaded_file{
  float: right; 
  font-size: 0.8rem;
  /* padding: 1px 18px; */
  text-align: right;
}
.plusButton{
  color: rgb(0, 46, 153);
  margin-right: 3px;
  text-align: right;

}
.fileColor{
  color: gray;
}
.fileErrorMessage{
  color: red;
  background-color:antiquewhite ;
  /* margin-left: 35.8rem; */
  font-size: 0.8rem;
  /* float: right; */
  position: relative;
}
.crossIcon{
    font-size: 0.9rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.fileSizeMessage{
  color: red;
  background-color: antiquewhite;
  /* margin-left: 29.4rem; */
  font-size: 0.8rem;
  /* float: right; */
}

.feedback-general {
  width: 217px;
  height: 84px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 64px;
  margin-left: 32px;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
}

.feedback-newidea {
  width: 373px;
  height: 84px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15); */
  border-radius: 8px;
  margin-bottom: 64px;
  margin-left: 32px;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
}

.feedback-type-label {
  /* height: 22px; */
  color: rgb(99, 115, 129);
  font-size: 1rem;
  font-family: GraphikMedium;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: -7%;
}
.feedback-type-labelSelected {
  /* height: 22px; */
  color: rgb(0,46,153);
  font-size: 1rem;
  font-family: GraphikMedium;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: -7%;
}

.feedback-typeSelected {
  /* height: 22px; */
  color: rgb(99, 115, 129);
  font-size: 1rem;
  font-family: GraphikMedium;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: 5%;
}

.feedback-typeSelectedChange {
  /* height: 22px; */
  color: rgb(0, 46, 153);
  font-size: 1rem;
  font-family: GraphikMedium;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: 5%;
}

.type-icon {
  width: 24px;
  height: 24px;
  position: relative;
  top: 15%;
}

.selected-type {
  width: 217px;
  height: 4px;
  background: rgb(0, 46, 153);
  border-radius: 0px 0px 4px 4px;
  position: relative;
  bottom: -8%;
}
.selected-typeFeedback {
  width: 217px;
  height: 4px;
  background: rgb(0, 46, 153);
  border-radius: 0px 0px 4px 4px;
  position: relative;
  bottom: -15%;
}
/*keerthi css */

.demoName {
  color: #ffffffd6;
  font-size: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
}

.demoName1 {
  color: #ffffffd6;
  font-size: 1rem;
  margin-bottom: .5rem;

  /* margin-bottom: .25rem; */
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.demo-popup .modal-content {
  background: rgb(3, 26, 85);
  border-radius: 8px;
  border: 1px solid rgb(154, 162, 241);
  box-shadow: 0px 2px 4px 0px rgba(157, 155, 155, 0.56);
  /* height: 395px;
  width: 1312px; */
  position: absolute;
  bottom: 7.6rem;
}
.demo-popup.modal-dialog {
  margin-left: 4rem !important;
}
.demo-popup .modal-content .modal-header .close {
  font-size: 2rem !important;
  color: rgb(1, 142, 255);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.3);
}

.demo-popup .modal-content .modal-header {
  border-bottom: none;
}

.flex-container {
  display: flex;
  justify-content: space-around;
}

.flex-container>div {
  margin: 10px;
  padding: 1.5rem;
  font-size: 30px;
}

.demo-content {
  background: rgb(16, 45, 116);
  border-radius: 0px;
  border: 1px solid rgb(235, 225, 248);
  height: 256px;
  width: 330px;
  text-align: center;
}

.pwd-align {
  color: #f9fafbc2;
  font-size: 14px;
  min-height: 40px;
  /* letter-spacing: -0.35px;
  line-height: 22px;
  text-align: center; */
  /* margin-bottom: 1rem !important; */
}

.DemoModal {
  max-width: 52% !important;
  /* position: absolute !important;
  left: 2rem;
  top: 7rem; */
}

.demo-popup .modal-content .modal-header {
  padding: 0rem 1rem;
}

/*raise an issue css*/
.wrapper {
  width: auto;
  height: auto;
}

.circle {
  width: 40px;
  height: 40px;
  background: dodgerblue;
  border-radius: 100px;
  position: relative;
  margin: 0px auto 0 auto;
}

.total-issues {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #fff;
}

.vertical-line {
  width: 2px;
  height: 32px;
  background: lightgray;
  margin: 0px auto 0 auto;
}

.feedback-footer-general {
  height: 72px;
  background: white;
  border-radius: 0px 0px 16px 16px;
  border-top: none !important;
}
/* raise issue css end */
/*keerthi css end */

.feedback-modal .close {
  color: #fff;
  padding-top: 0.7rem !important;
}

.feedback-modal .close:hover {
  color: #fff;
}
.requestAccess {
  padding: 8px 19px;
  text-decoration: none ;
  color: white;
}
.requestButton a:hover{
    color: white;
    text-decoration: none!important;
} 
.descRequestAccess {
  min-height: 3.2rem;
}
.copy_Password {
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-height: 600px) and (max-height: 750px)
{
  .footer-space {
    height: 9px;
  }
  .power {
    font-size: 2.2rem;
  }
  .intOps {
    font-size: 2.2rem;
  } 
  .appName {
    font-size: 28px;
  }
  .header-left {
    padding-top: 1.2rem !important;
  }
  .description {
    min-height: 2.2rem;
  }
  .icon {
    height: 28px;
    min-width: 24px;
  }
  .tile-container {
    padding-top: 1rem;
  }
  .footer-up {
    margin-top: 0px;
  }
  .dealName {
    font-size: 13px;
  }
  .footer-up .col-7 li {
    padding-top: 1.4rem;
    font-size: 11px;
  }
  .getStarted {
    height: 30px;
  } 
  .header-right {
    padding-top: 20px;
  }
  .footer-up .copyright li {
    padding-top: 1.4rem;
    font-size: 12px;
  }
  .footer-up .acclogo li {
    padding-top: 0.8rem;
  }
  .helpIcon { 
    bottom: 2.1rem;
  }
  .body-container {
    padding-top: 0.8rem;
    min-height: 73vh;
  }
  .synopsLogo {
    height: 45px;
    width: 45px;
    margin-bottom: 0.5rem;
  }
  .demo-popup .modal-content {
    bottom: 2.7rem;
  }

}
.top-position-app{
  position: relative;
  top: -15vh;
}