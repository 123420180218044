@font-face {
	font-family: 'GraphikBlack';
	src: local('☺'), url('Graphik-Black.woff') format('woff'), url('Graphik-Black.ttf') format('truetype'), url('Graphik-Black.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GraphikBlackIE';
	src: url('Graphik-Black.eot');
}
@font-face {
	font-family: 'GraphikRegular';
	src: local('☺'), url('Graphik-Regular.woff') format('woff'), url('Graphik-Regular.ttf') format('truetype'), url('Graphik-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GraphikRegularIE';
	src: url('Graphik-Regular.eot');
}
@font-face {
	font-family: 'GraphikBold';
	src: local('☺'), url('Graphik-Bold.woff') format('woff'), url('Graphik-Bold.ttf') format('truetype'), url('Graphik-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GraphikBoldIE';
	src: url('Graphik-Bold.eot');
}
@font-face {
	font-family: 'GraphikSemibold';
	src: local('☺'), url('Graphik-Semibold.woff') format('woff'), url('Graphik-Semibold.ttf') format('truetype'), url('Graphik-Semibold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GraphikSemiboldIE';
	src: url('Graphik-Semibold.eot');
}
@font-face {
	font-family: 'GraphikMedium';
	src: local('☺'), url('Graphik-Medium.woff') format('woff'), url('Graphik-Medium.ttf') format('truetype'), url('Graphik-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GraphikMediumIE';
	src: url('Graphik-Medium.eot');
}
@font-face {
	font-family: 'GraphikLight';
	src: local('☺'), url('Graphik-Light.woff') format('woff'), url('Graphik-Light.ttf') format('truetype'), url('Graphik-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GraphikLightIE';
	src: url('Graphik-Light.eot');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GraphikSuper';
	src: url('Graphik-Super.ttf');
	font-weight: normal;
	font-style: normal;
}