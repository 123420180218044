body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
div.languageDropdown-options::-webkit-scrollbar {
  width: 10px !important;
}
/* ::-webkit-scrollbar {
  width: 2px !important;
} */
::-webkit-scrollbar {
  width: 10px!important;
}
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
/* Track */
div.languageDropdown-options::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;
  background: #0242c6 !important;
}

/* Handle */
div.languageDropdown-options::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
div.languageDropdown-options::-webkit-scrollbar-thumb,
div.languageDropdown-options::-webkit-scrollbar-thumb:hover {
  background: #000663 !important;
}
/* ::-webkit-scrollbar-thumb,::-webkit-scrollbar-thumb:hover {
  background: transparent;
} */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey!important;
  border-radius: 10px!important;
  background: #0242c6!important;
}
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  background: #000663!important;
}
/* ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  background: #48abff;
} */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #48abff;
}
::-webkit-scrollbar-thumb:hover {
  background: #48abff;
}
